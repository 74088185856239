export type EmployeeList = {
  firstName: string;
  lastName: string;
  profile:
    | 'Executive'
    | 'R&D Scientist'
    | 'Software Engineer'
    | 'Machine Learning Engineer'
    | 'Visualization Engineer'
    | 'Office Manager'
    | 'Project Manager';
  title?: string;
  image: string;
};

const employeeList: EmployeeList[] = [
  {
    firstName: 'Georges',
    lastName: 'Khazen',
    profile: 'Executive',
    title: 'Chief Executive Officer',
    image: '',
  },
  {
    firstName: 'Jean-Denis',
    lastName: 'Courcol',
    profile: 'Executive',
    title: 'Chier Technology Officer',
    image: '',
  },
  {
    firstName: 'Michael',
    lastName: 'Reimann',
    profile: 'Executive',
    title: 'Chief Scientific Officer',
    image: '',
  },
  {
    firstName: 'Allessandro',
    lastName: 'Cattabiani',
    profile: 'Software Engineer',
    image: '',
  },
  {
    firstName: 'Alina',
    lastName: 'Busuioc Jimenez',
    profile: 'Office Manager',
    image: '',
  },
  {
    firstName: 'Armando',
    lastName: 'Romani',
    profile: 'R&D Scientist',
    image: '',
  },
  {
    firstName: 'Aurelien',
    lastName: 'Jaquier',
    profile: 'R&D Scientist',
    image: '',
  },
  {
    firstName: 'Ayima',
    lastName: 'Okeeva',
    profile: 'Software Engineer',
    image: '',
  },
  {
    firstName: 'Bilal',
    lastName: 'Meddah',
    profile: 'Software Engineer',
    image: '',
  },
  {
    firstName: 'Boris',
    lastName: 'Bergsma',
    profile: 'Machine Learning Engineer',
    image: '',
  },
  {
    firstName: 'Christoph',
    lastName: 'Pokorny',
    profile: 'R&D Scientist',
    image: '',
  },
  {
    firstName: 'Daniel',
    lastName: 'Fernandez',
    profile: 'Software Engineer',
    image: '',
  },
  {
    firstName: 'Daniel',
    lastName: 'Keller',
    profile: 'R&D Scientist',
    image: '',
  },
  {
    firstName: 'Daniela',
    lastName: 'Egas Santander',
    profile: 'R&D Scientist',
    image: '',
  },
  {
    firstName: 'Darshan',
    lastName: 'Mandge',
    profile: 'R&D Scientist',
    image: '',
  },
  {
    firstName: 'Dinika',
    lastName: 'Saxena',
    profile: 'Software Engineer',
    image: '',
  },
  {
    firstName: 'Dries',
    lastName: 'Verachtert',
    profile: 'Software Engineer',
    image: '',
  },
  {
    firstName: 'Eleftherios',
    lastName: 'Zisis',
    profile: 'Software Engineer',
    image: '',
  },
  {
    firstName: 'Elvis',
    lastName: 'Boci',
    profile: 'Visualization Engineer',
    image: '',
  },
  {
    firstName: 'Erik',
    lastName: 'Heeren',
    profile: 'Software Engineer',
    image: '',
  },
  {
    firstName: 'Eva',
    lastName: 'Kenny',
    profile: 'Project Manager',
    image: '',
  },
  {
    firstName: 'Fabien',
    lastName: 'Petitjean',
    profile: 'Software Engineer',
    image: '',
  },
  {
    firstName: 'Gianluca',
    lastName: 'Ficarelli',
    profile: 'Software Engineer',
    image: '',
  },
  {
    firstName: 'Gil Arturo',
    lastName: 'Barrios Del Villar',
    profile: 'Software Engineer',
    image: '',
  },
  {
    firstName: 'Ilkan',
    lastName: 'Kiliç',
    profile: 'Software Engineer',
    image: '',
  },
  {
    firstName: 'James Gonzalo',
    lastName: 'King',
    profile: 'Software Engineer',
    image: '',
  },
  {
    firstName: 'James',
    lastName: 'Isbister',
    profile: 'R&D Scientist',
    image: '',
  },
  {
    firstName: 'Jan',
    lastName: 'Krepl',
    profile: 'Machine Learning Engineer',
    image: '',
  },
  {
    firstName: 'Juan José',
    lastName: 'García Cantero',
    profile: 'R&D Scientist',
    image: '',
  },
  {
    firstName: 'Judit',
    lastName: 'Planas',
    profile: 'Software Engineer',
    image: '',
  },
  {
    firstName: 'Lida',
    lastName: 'Kanari',
    profile: 'R&D Scientist',
    image: '',
  },
  {
    firstName: 'Mathieu',
    lastName: 'Chambon',
    profile: 'Visualization Engineer',
    image: '',
  },
  {
    firstName: 'Mike',
    lastName: 'Gevaert',
    profile: 'Software Engineer',
    image: '',
  },
  {
    firstName: 'Natali',
    lastName: 'Barros',
    profile: 'R&D Scientist',
    image: '',
  },
  {
    firstName: 'Nicolas',
    lastName: 'Frank',
    profile: 'Machine Learning Engineer',
    image: '',
  },
  {
    firstName: 'Loris',
    lastName: 'Olivier',
    profile: 'Visualization Engineer',
    image: '',
  },
  {
    firstName: 'Pavlo',
    lastName: 'Getta',
    profile: 'Software Engineer',
    image: '',
  },
  {
    firstName: 'Weina',
    lastName: 'Ji',
    profile: 'R&D Scientist',
    image: '',
  },
];

export default employeeList;
