export default function IconTwitter({
  iconColor = 'currentColor',
  className,
}: {
  iconColor?: string;
  className?: string;
}) {
  return (
    <svg
      width="2em"
      height="2em"
      viewBox="0 0 256 256"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill="#fff"
        d="M12.61,2h230.77c5.86,0,10.61,4.76,10.61,10.61v230.77c0,5.86-4.76,10.61-10.61,10.61H12.61c-5.86,0-10.61-4.76-10.61-10.61V12.61C2,6.76,6.76,2,12.61,2Z"
      />
      <path
        fill={`color-mix(in srgb, ${iconColor}, #000)`}
        d="M243.39,4c4.75,0,8.61,3.86,8.61,8.61v230.77c0,4.75-3.86,8.61-8.61,8.61H12.61c-4.75,0-8.61-3.86-8.61-8.61V12.61c0-4.75,3.86-8.61,8.61-8.61h230.77M243.39,0H12.61C5.65,0,0,5.65,0,12.61v230.77c0,6.97,5.65,12.61,12.61,12.61h230.77c6.97,0,12.61-5.65,12.61-12.61V12.61C256,5.65,250.35,0,243.39,0h0Z"
      />
      <path
        fill={iconColor}
        d="M140.15,118.06l46.95-54.69h-11.05l-40.88,47.51-32.59-47.51h-37.56l49.16,71.81-49.16,57.45h11.05l43.09-50.27,34.25,50.27h37.56l-50.82-74.58ZM124.69,135.73l-4.97-7.18-39.77-56.9h17.12l32.04,45.85,4.97,7.18,41.98,59.66h-17.12l-34.25-48.61Z"
      />
    </svg>
  );
}
