export default function IconBlueSky({
  iconColor = 'currentColor',
  className,
}: {
  iconColor?: string;
  className?: string;
}) {
  return (
    <svg
      width="2em"
      height="2em"
      viewBox="0 0 256 256"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill="#fff"
        d="M12.61,2h230.77c5.86,0,10.61,4.76,10.61,10.61v230.77c0,5.86-4.76,10.61-10.61,10.61H12.61c-5.86,0-10.61-4.76-10.61-10.61V12.61C2,6.75,6.76,2,12.61,2Z"
      />
      <path
        fill={`color-mix(in srgb, #000, ${iconColor})`}
        d="M243.39,4c4.75,0,8.61,3.86,8.61,8.61v230.77c0,4.75-3.86,8.61-8.61,8.61H12.61c-4.75,0-8.61-3.86-8.61-8.61V12.61C4,7.86,7.86,4,12.61,4h230.77M243.39,0H12.61C5.65,0,0,5.65,0,12.61v230.77c0,6.97,5.65,12.61,12.61,12.61h230.77c6.97,0,12.61-5.65,12.61-12.61V12.61C256,5.65,250.35,0,243.39,0h0Z"
      />
      <path
        fill={iconColor}
        d="M86.42,72.07c16.83,12.64,34.94,38.26,41.58,52.01,6.65-13.75,24.75-39.37,41.58-52.01,12.14-9.12,31.82-16.17,31.82,6.28,0,4.48-2.57,37.66-4.08,43.05-5.24,18.73-24.34,23.5-41.32,20.61,29.69,5.05,37.24,21.79,20.93,38.53-30.98,31.79-44.53-7.98-48-18.17-.64-1.87-.93-2.74-.94-2,0-.74-.3.13-.94,2-3.47,10.19-17.02,49.96-48,18.17-16.31-16.74-8.76-33.48,20.93-38.53-16.99,2.89-36.08-1.89-41.32-20.61-1.51-5.39-4.08-38.57-4.08-43.05,0-22.45,19.68-15.39,31.82-6.28h0Z"
      />
    </svg>
  );
}
